import React from 'react';
import FooterComponent from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { WhatsappButtonComponent } from '../../components/whatsapp-button/whatsapp-button.component';
import "./main.layout.scss";

export const MainLayout = (props: any) => {
  return (
    <>
      <HeaderComponent {...props.siteMetaData} />

      <main className='main-layout'>
        {props.children}
      </main>

      <WhatsappButtonComponent />
      <FooterComponent siteMetaData={props.siteMetaData} />
    </>
  )
}