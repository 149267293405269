import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Mensaje404Component } from "../components/mensaje.404/mensaje.404.component";
import { MainLayout } from "../layouts/main/main.layout";

const NotFoundPage = () => {
  const siteMetaData404 = useStaticQuery(
    graphql`
      query SiteMetaData404 {
        site {
          siteMetadata {
            author
            description
            image
            menuLinks {
              link
              name
            }
            siteUrl
            title
            url
            lang
            charSet
          }
        }
      }        
    `
  );

  return (
      <MainLayout className={"footer"}  siteMetaData = {siteMetaData404.site.siteMetadata}>
          <Mensaje404Component/>
      </MainLayout>
  );
}

export default NotFoundPage
